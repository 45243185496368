@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full bg-eo-nincada font-montserrat text-eo-beautifly scroll-pt-28 md:scroll-pt-36 lg:scroll-pt-40 scroll-smooth;
  }

  section {
    @apply relative last:mb-16 last:lg:mb-40;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  p {
    @apply text-[15px] sm:text-base mb-4 sm:mb-7 last:mb-0;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  textarea {
    @apply resize-none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-co-headline;
  }
}

@layer components {
  .btn--blue {
    @apply btn--base btn--pseudo border-transparent bg-eo-whiscash text-white after:content-arrow-white;
  }

  .btn--blue--outline {
    @apply btn--base btn--pseudo border-eo-whiscash text-eo-whiscash after:content-arrow-blue;
  }

  .btn--blue--transparent {
    @apply btn--base btn--pseudo border-transparent text-eo-whiscash after:content-arrow-blue;
  }

  .btn--blue--transparent--reverse {
    @apply btn--base btn--pseudo border-transparent text-eo-whiscash before:content-arrow-blue before:rotate-180 before:mr-3 before:h-9 before:w-9 before:transition-transform hover:before:-translate-x-1;
  }

  .btn--white {
    @apply btn--base btn--pseudo border-transparent bg-white text-eo-buizel after:content-arrow-orange;
  }

  .btn--white--blue {
    @apply btn--base btn--pseudo border-transparent bg-white text-eo-whiscash after:content-arrow-blue;
  }

  .btn--white--transparent {
    @apply btn--base btn--pseudo border-transparent text-eo-buizel after:content-arrow-orange;
  }

  .btn--alt {
    @apply btn--base btn--pseudo border border-eo-buizel bg-transparent text-eo-buizel [&:not([disabled])]:focus:bg-eo-buizel [&:not([disabled])]:focus:text-white;
  }

  .section {
    @apply py-6 lg:py-12;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-colors disabled:opacity-75 [&:not([disabled])]:hover:underline;
  }

  .btn--base {
    @apply text-sm sm:text-base inline-flex items-center justify-between rounded-full border-2 py-1 pl-6 pr-3 font-semibold after:ml-3 after:h-9 after:w-9 after:transition-transform [&:not([disabled])]:hover:after:translate-x-1;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-eo-buizel;
  }

  .content--base {
    @apply children-b:font-bold children-strong:font-bold last:[&>*]:mb-0;
  }

  .content--paragraphs {
    @apply children-p:mb-4 children-p:font-light children-p:leading-6;
  }

  .content--headings {
    @apply children-headings:mb-4 children-headings:font-bold children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply mx-auto my-0 block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-eo-buizel;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-group {
  @apply relative mb-6 lg:mb-12;
}

.form-duuf-submit > button {
  @apply btn--blue disabled:bg-transparent disabled:border-eo-whiscash disabled:text-eo-whiscash;
}

.form-duuf-loading {
  @apply flex-center absolute inset-0 z-10 bg-transparent;
}

.form-duuf-label {
  @apply block px-4 mb-2;
}

.form-duuf-group-checkbox .form-duuf-label {
  @apply font-bold pl-0;
}

.form-duuf-group-signup {
  @apply lg:!mb-[7.8rem];
}

.form-duuf-group-signup > div > div {
  @apply !my-2;
}

.form-duuf-group.form-duuf-group-consent,
.form-duuf-group.form-duuf-group-textarea {
  @apply mb-8;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply rounded-full border w-full border-[#DDD] px-4 py-2;
}

.form-duuf-textarea {
  @apply min-h-[166px] rounded-2xl;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--alt px-2 py-1;
}

.form-duuf-group-consent > div,
.form-duuf-group-checkbox > div > div {
  @apply items-start;
}

.form-duuf-group-name-first {
  @apply w-full;
}

.form-duuf-checkbox.form-duuf-checkbox-consent,
.form-duuf-checkbox.form-duuf-checkbox-checkbox {
  @apply mt-1 mr-2;
}

.form-duuf-group-consent a {
  @apply underline hover:text-eo-buizel;
}

.form-duuf-error {
  @apply absolute right-0 text-eo-ledian top-0 text-sm;
}

/* Custom Forms */

.form-modal .form-duuf-label,
.form-modal .form-duuf-checkbox-label {
  @apply text-eo-claydol;
}

.form-modal > form {
  @apply grid grid-cols-2 gap-4;
}

.form-modal .form-duuf-group-textarea {
  @apply col-span-2;
}

.form-modal .form-duuf-group-consent {
  @apply col-span-2;
}

.form-modal .form-duuf-group {
  @apply relative mb-2 lg:mb-4;
}

.form-employee-experience .form-duuf-group {
  @apply mb-4 lg:mb-8;
}

.form-employee-experience .form-duuf-group-radio {
  @apply !mb-2;
}

.form-employee-experience .form-duuf-group-radio > div {
  @apply grid grid-cols-5 gap-3 lg:gap-5 mt-8;
}

.form-employee-experience .form-duuf-group-radio > div > div {
  @apply bg-white col-span-1 shadow-md rounded-2xl aspect-square flex justify-center items-center text-4xl lg:text-7xl text-eo-buizel font-bold font-co-headline;
}

.form-employee-experience .form-duuf-group-radio > div > div > label {
  @apply absolute pointer-events-none;
}

.form-employee-experience .form-duuf-group-radio > div > div > input {
  @apply cursor-pointer w-full h-full border-4 border-transparent !appearance-none hover:border-eo-buizel/50 focus:border-eo-buizel checked:border-eo-buizel rounded-2xl;
}

.form-employee-experience .form-duuf-group-radio .form-duuf-label {
  @apply relative inline-block italic bg-white border-4 border-eo-whiscash rounded-3xl shadow-md px-7 py-5 before:absolute before:-bottom-[5px] sm:before:-bottom-[10px] before:-left-4;
}

.form-employee-experience .form-duuf-label {
  @apply text-[15px] sm:text-base;
}

.form-download form {
  @apply grid grid-cols-2 gap-x-7;
}

.form-download .form-duuf-group {
  @apply col-span-2 sm:col-span-1;
}

.form-download .form-duuf-group-consent .form-duuf-label {
  @apply opacity-0;
}

.form-popup form {
  @apply grid grid-cols-2 gap-x-7;
}

.form-popup .form-duuf-group {
  @apply mb-7;
}

.form-popup .form-duuf-group,
.form-popup .form-duuf-group-name {
  @apply col-span-2 lg:col-span-1;
}

.form-popup .form-duuf-group-textarea {
  @apply col-span-2;
}

.form-popup .form-duuf-label,
.form-popup .form-duuf-checkbox-label {
  @apply text-eo-beautifly;
}

.form-duuf-error-consent {
  @apply absolute -top-6 right-0;
}

.form-newsletter .form-duuf-group {
  @apply mb-4;
}

/* Swiper */

.swiper-button-disabled {
  @apply opacity-50;
}

/* Content */
.parsecontent ul {
  @apply list-disc pl-6 my-4 text-sm sm:text-base;
}

.parsecontent li {
  @apply leading-7;
}

.parsecontent a {
  @apply text-eo-buizel hover:underline;
}

/* React Modal */

.ReactModal__Content {
  @apply !translate-y-full !-translate-x-1/2 transition-transform duration-300;
}

.ReactModal__Content--after-open {
  @apply !-translate-y-1/2;
}

.ReactModal__Content--before-close {
  @apply !-translate-x-1/2 !translate-y-full;
}
